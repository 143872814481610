<template>
    <div class="two-up page-layout">
        <div class="slot">
            <slot name="slot1" />
        </div>
        <div class="slot">
            <slot name="slot2" />
        </div>
    </div>
</template>

<script>
export default {
name: 'TwoUpLayout'

};
</script>

<style lang="scss" scoped>
.two-up {
    display: flex;
    flex-direction: column;

    .slot {
        margin-top: 30px;
        width: 100%;
    }
}
</style>